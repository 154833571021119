<template>
  <div class="w-full my-auto p-4 border-gray-200">
    <!-- model list -->
    <div class="flex">
      <!-- model name -->
      <div class="flex flex-col">
        <div
          v-for="(model, index) in merged_model_list" :key="index"
          class="relative ml-6 mr-2 my-3"
        >
          <label
            v-if="model.rank"
            :style="'background: ' + get_rank_color(model.rank)"
            class="absolute right-full w-5 text-center text-white rounded-lg vertical_center"
          >
            {{ model.rank }}
          </label>
          <div class="font-bold mx-2">
            {{ model.name }}
          </div>
        </div>
      </div>
      <!-- model init time -->
      <div class="flex-1 overflow-x-scroll">
        <div
          v-for="(model, index) in merged_model_list" :key="index"
          class="flex"
        >
          <label
            v-for="(_time, index) in get_model_time_list(model)" :key="index"
            class="my-3 mx-1 relative"
          >
            <input
              type="checkbox"
              v-model="selected_models"
              :value="{'name': model.name, 'init_time': _time}"
              class="peer sr-only"
            >
            <div
              v-if="check_model_threshold(model.name, _time)"
              class='icon-notification-2 threshold_icon'
            ></div>
            <div
              class="checkbox_tag"
              :style="get_model_checked_style(model.name, _time)"
            >
              <span>{{ model_time_format(_time, model) }}</span>
            </div>
          </label>
        </div>
      </div>
    </div>
    <hr class="divide-solid my-2">
    <cb-select v-model="selected_algorithm">
      <option
        v-for="(method, idx) in algorithms"
        :key="'method_' + idx"
        :value="method.en"
      >
      {{method.ch}}
      </option>
    </cb-select>
    <hr class="divide-solid my-2">
    <div class="flex justify-end">
      <button
        class="flex items-center w-[165px] btn_primary--line"
        @click="calc_ensemble"
      >
        Ensemble<i class="icon-arrow_right text-md mx-1"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { MainVState } from '@/state/main'
import {MODEL_COLOR, MODEL_RANK_COLOR} from '@/settings/model'

const MODEL_ORDER = ['BMA', 'MOS-DCA', 'OP10', 'OP20', 'AQF4', 'RCEC']

export default {
  data() {
    return {
      __: MainVState,
      model_info: [],
      obs_info: [],
      algorithms: [
        {
          ch: '平均值',
          en: 'avg'
        },{
          ch: '中位數',
          en: 'median'
        },{
          ch: '最大值',
          en: 'maximum'
        },{
          ch: '高標值50%',
          en: 'maximum 50%'
        },{
          ch: '最小值',
          en: 'minimum'
        }
      ],
    }
  },

  computed: {
    fcst_datetime_selected() {
      return MainVState.fcst_datetime_selected
    },

    fcst_datetime_selected_iso() {
      return MainVState.fcst_datetime_selected.isostr()
    },

    merged_model_list() {
      return this.obs_info.concat(this.model_info)
    },

    selected_pollutant_list() {
      return MainVState.selected_pollutant_list
    },

    selected_area_list() {
      return MainVState.selected_area_list
    },

    unit() {
      return MainVState.unit
    },

    selected_models: {
      get() {
        return MainVState.selected_models
      },
      set(val) {
        MainVState.set_selected_models(val)
      }
    },

    selected_algorithm: {
      get() {
        return MainVState.selected_algorithm
      },
      set(val) {
        MainVState.set_selected_algorithm(val)
      }
    }
  },

  watch: {
  },

  methods: {
    check_model_threshold(model_name, init_time) {
      let _model_info = this.model_info.filter(model_info => model_info.name === model_name)
      if (model_name.startsWith('OP10') || model_name.startsWith('BMA') || model_name.startsWith('MOS')) {
        let today = new Date().toLST().zero_hour()
        let limit_day = today.addDays(-2)
        if (init_time <  limit_day) {
          return
        }
      }
      if (_model_info.length) {
        let key = Object.keys(_model_info[0].threshold).filter(_init_time => new Date(_init_time).valueOf() === init_time.valueOf())
        // error handing
        if (!_model_info[0].threshold[key]) return
        let fcst_datetime = Object.keys(_model_info[0].threshold[key]).filter(threshold_init_time => threshold_init_time === this.fcst_datetime_selected_iso)
        if (fcst_datetime) {
          let is_threshold = _model_info[0].threshold[key][fcst_datetime]
          return !is_threshold
        }
      return
      }
    },

    get_rank_color(rank) {
      return MODEL_RANK_COLOR[rank-1]
    },

    get_model_checked_style(model_name, init_time) {
      let background_color = 'rgb(248, 248, 248)'
      let font_color = MODEL_COLOR[model_name]
      let selected_model = this.selected_models.filter(model_info => model_info.name === model_name && model_info.init_time === init_time)
      if (selected_model.length) {
        background_color = MODEL_COLOR[model_name]
        font_color = 'white'
      }

      return {
        'class': 'icon-notification-2',
        'background': background_color,
        'color': font_color
      }
    },

    get_model_time_list(model) {
      if (model.hasOwnProperty('obs_time')) {
        return model.obs_time
      }
      return model.init_time
    },

    model_time_format(time, model) {
      if (model.hasOwnProperty('obs_time')) {
        return time.toLST().strftime('%m-%d %H:%M')
      }
      else {
        if (model.name.startsWith('OP10') || model.name.startsWith('BMA') || model.name.startsWith('MOS')) {
          let today = new Date().toLST().zero_hour()
          let limit_day = today.addDays(-2)
          if (time >  limit_day) {
            return time.strftimez('%d %HZ')
          }
          return
        }
        return time.strftimez('%d %HZ')
      }
    },

    calc_ensemble() {
      MainVState.get_ensemble({
        algorithm: this.selected_algorithm,
        selected_models: this.selected_models,
      })
    }
  },

  mounted() {
    MainVState.get_model_info().then((data) => {
      if (data) {
        // 替預報做排序
        // 排序需求：照 MODEL_ORDER 的順序排
        // 實作補充：沒在 MODEL_OREDER 列出的會排在最後，同家族的會照字母順序排
        let model_list = data.map(model => {
          for (let [idx, order_name] of Object.entries(MODEL_ORDER)) {
            if (model.name.startsWith(order_name)) {
              return {
                ...model,
                order: idx
              }
            }
          }
          return {
            ...model,
            order: MODEL_ORDER.length
          }
        })
        this.model_info = model_list.sort((a, b) => {
          if (a.order < b.order) {
            return -1
          }
          else if (a.order > b.order) {
            return 1
          }
          else {
            if (a.name < b.name) {
              return -1
            }
            else if (a.name > b.name) {
              return 1
            }
          }
        })
      }
    })

    MainVState.get_obs_info().then((data) => {
      if (data) {
        this.obs_info = data
      }
    })
  }
}

</script>

<style scoped>
.checkbox_tag {
  @apply px-2;
  @apply rounded-lg cursor-pointer;
  @apply font-bold whitespace-nowrap;
}

.threshold_icon {
  color: red;
  position: absolute;
  right: -6px;
  top: -5px;
  opacity: 0.8;
}
</style>
