<template>
  <div class="m-1">
    <div v-if="compute_aqi_type" class="relative">
      <input
        type="number"
        v-model="primary_secondary_aqi"
        @input="validate_value($event, 'primary_secondary_aqi')"
        :style="`border-top-width: 20px; border-top-color: ${color_mapping(primary_secondary_aqi)};`"
        class="pollutant_input"
      />
      <!--顯示主要/次要AQI所對應的污染物-->
      <div
        class="absolute top-1 horizontal_center text-xs font-bold text-white"
        :class="{'text-gray-800': [0, 2].includes(get_severity_level(primary_secondary_aqi))}"
      >
        {{ primary_secondary_aqi_pollutant }}
      </div>
    </div>
    <div v-else>
      <input
        type="number"
        :value="pollutant_value"
        @change="pollutant_value = $event.target.value"
        @input="validate_value($event, 'pollutant_value')"
        :style="`border-top-color: ${color_mapping(pollutant_aqi_value)};`"
        class="pollutant_input"
      />
    </div>
  </div>
</template>
<script>

import { MainVState } from '@/state/main'

export default {
  name: 'ValueInput',

  props: {
    area: {
      type: String,
      default: null
    },
    pollutant: {
      type: String,
      default: null
    },
    compute_aqi_type: {
      type: String,
      default: null
    },
  },

  data() {
    return {
    }
  },

  methods: {
    get_severity_level(AQI) {
      return MainVState.get_severity_level(AQI)
    },

    color_mapping(AQI) {
      return MainVState.get_color_mapping(AQI)
    },

    validate_value(event, item) {
      // Todo: 目前可以輸入 1e2, 003
      let value = Number(event.target.value)
      if (value < 0 || Number.isNaN(value)) {
        this.$set(this, item, 0)
      }
    },

    aqi_to_concentration(pollutant, aqi_value) {
      // AQI對照表參考：https://airtw.moenv.gov.tw/cht/Information/Standard/AirQualityIndicator.aspx
      const pollutant_ranges = {
        'PM10': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 30, 75, 190, 354, 424, 504, 604],
        },
        'PM2.5': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 12.4, 30.4, 50.4, 125.4, 225.4, 325.4, 500.4],
        },
        'O3_8hr': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300],
          'concentration_ranges': [0, 54, 70, 85, 105, 200],
        },
        'O3': {
          'aqi_ranges': [100, 150, 200, 300, 400, 500],
          'concentration_ranges': [100, 134, 204, 404, 504, 604],
        },
        'CO': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 4.4, 9.4, 12.4, 15.4, 30.4, 40.4, 50.4],
        },
        'SO2': {
          'aqi_ranges':  [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 8, 65, 160, 304, 604, 804, 1004],
        },
        'NO2': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 21, 100, 360, 649, 1249, 1649, 2049],
        },
      }

      const ranges = pollutant_ranges[pollutant]
      if (!ranges) {
        throw new Error(`Unsupported pollutant: ${pollutant}`)
      }

      for (let i = 0; i < ranges.aqi_ranges.length - 1; i++) {
        if (ranges.aqi_ranges[i] <= aqi_value && aqi_value <= ranges.aqi_ranges[i + 1]) {
          const result = ((aqi_value - ranges.aqi_ranges[i]) /
              (ranges.aqi_ranges[i + 1] - ranges.aqi_ranges[i])) *
              (ranges.concentration_ranges[i + 1] - ranges.concentration_ranges[i]) +
              ranges.concentration_ranges[i]

          return Math.floor(result)
        }
      }
      
      return undefined
    },

    concentration_to_aqi(pollutant, value) {
      // AQI對照表參考：https://airtw.moenv.gov.tw/cht/Information/Standard/AirQualityIndicator.aspx
      const pollutant_ranges = {
        'PM10': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 30, 75, 190, 354, 424, 504, 604],
        },
        'PM2.5': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 12.4, 30.4, 50.4, 125.4, 225.4, 325.4, 500.4],
        },
        'O3_8hr': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300],
          'concentration_ranges': [0, 54, 70, 85, 105, 200],
        },
        'O3': {
          'aqi_ranges': [100, 150, 200, 300, 400, 500],
          'concentration_ranges': [100, 134, 204, 404, 504, 604],
        },
        'CO': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 4.4, 9.4, 12.4, 15.4, 30.4, 40.4, 50.4],
        },
        'SO2': {
          'aqi_ranges':  [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 8, 65, 160, 304, 604, 804, 1004],
        },
        'NO2': {
          'aqi_ranges': [0, 50, 100, 150, 200, 300, 400, 500],
          'concentration_ranges': [0, 21, 100, 360, 649, 1249, 1649, 2049],
        },
      }

      const ranges = pollutant_ranges[pollutant]
      if (!ranges) {
        throw new Error(`Unsupported pollutant: ${pollutant}`)
      }

      for (let i = 0; i < ranges.concentration_ranges.length - 1; i++) {
        if (ranges.concentration_ranges[i] <= value  && value <= ranges.concentration_ranges[i + 1]) {
          const result =
            ((value - ranges.concentration_ranges[i]) /
              (ranges.concentration_ranges[i + 1] - ranges.concentration_ranges[i])) *
              (ranges.aqi_ranges[i + 1] - ranges.aqi_ranges[i]) +
            ranges.aqi_ranges[i]
          return Math.ceil(result / 5) * 5 // Round to nearest multiple of 5
        }
      }

      return undefined
    }

  },

  computed: {
    editing_type() {
      return MainVState.editing_type
    },

    editing_aqi_data() {
      return MainVState.get_editing_aqi_data(this.area, this.compute_aqi_type)
    },

    primary_secondary_aqi: {
      get() {
        // 調整污染物數據時，在 MainVState 會自動重算 primary/secondary AOI
        // 直接 get 取值來更新
        return this.editing_aqi_data.value
      },

      set(val) {
        // 手動直接調整 primary/secondary AOI 時，將數據傳回 MainVState，地圖才會變色
        MainVState.set_editing_aqi_data(this.area, this.compute_aqi_type, val, this.pollutant)
      }
    },

    primary_secondary_aqi_pollutant() {
      // primary/secondary AQI對應的污染物，當不同污染物AQI相同時依PM2.5、O3-8hr、O3、PM10、SO2、NO2排序
      return this.editing_aqi_data.pollutant
    },

    pollutant_aqi_value() {
      return MainVState.get_editing_pollutant_data(this.area, this.pollutant, 'AQI')
    },

    pollutant_value: {
      get() {
        return MainVState.get_editing_pollutant_data(this.area, this.pollutant)
      },

      set(val) {
        if (this.editing_type === 'AQI') {
          MainVState.set_editing_pollutant_data(
              this.area, this.pollutant, 'AQI', val)
          MainVState.set_editing_pollutant_data(
              this.area, this.pollutant, 'concentration', this.aqi_to_concentration(this.pollutant, val))
        }
        else {
          MainVState.set_editing_pollutant_data(
              this.area, this.pollutant, 'concentration', val)
          MainVState.set_editing_pollutant_data(
              this.area, this.pollutant, 'AQI', this.concentration_to_aqi(this.pollutant, val))
        }
      }
    }
  },

  watch: {
  },

  mounted() {

  },

  created() {

  }
}
</script>

<style lang="scss" scoped>
.pollutant_input {
  @apply rounded-lg border-none outline-none;
  @apply w-[86px] h-[60px] text-lg text-center bg-gray-50 shadow-inner;
  @apply border-solid border-t-8 border-primary;
}

// 隱藏 number input 右邊的上下箭頭
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
